html {
  font-size: 16px;
}

@media only screen and (max-width: 1441px) {
  html {
    font-size: 80%;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 100%;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiTableCell-sizeSmall {
  padding: 4px 24px 4px 16px !important;
}

@media (min-width: 600px) {
  .MuiToolbar-regular {
    min-height: 45px !important;
    height: 45px;
  }
}

.previews {
  margin-bottom: -4px;
  background: #ffffff;
  width: 100%;
}

.previews button {
  display: none;
}

@media print {
  @page {
    margin: 0 auto;
    size: A4 portrait;
  }

  html {
    font-size: 10px;
  }

  .no-print {
    display: none !important;
  }

  header {
    display: none !important;
  }

  main {
    display: block !important;
    margin-top: -48px !important;
  }

  .MuiPaper-root {
    box-shadow: none !important;
  }

  main #main-content {
    padding: 0;
  }
}
